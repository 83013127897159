import React from 'react';

interface MessageWithPhotoProps {
    name: string;
    message: string;
    isUser: boolean;
}

const MessageWithPhoto: React.FC<MessageWithPhotoProps> = ({ name, message, isUser }) => {

    const messageClassName = isUser ? 'user' : 'bot';

    return (
        <div className={`message ${messageClassName}`}>
            <div className={`message-content ${messageClassName}`}>
                <div className="message-header">
                    {!isUser && <p className="message-name">Boot</p>}
                    <div className="message-photo">
                        <img
                            className={isUser ? 'user-image' : 'bot-image'}
                        src={isUser ? 'https://cdn-icons-png.flaticon.com/512/2815/2815428.png' : 'https://alinhamentodemotos.com.br/wp-content/uploads/2019/05/chat-loading-avatar.png'} alt={isUser ? 'User' : 'Bot'} />
                    </div>
                    {isUser && <p className="message-name">{name}</p>}

                </div>
                <div className="message-text">
                    {message}
                </div>
            </div>
        </div>
    );
};

export default MessageWithPhoto;
