import React, { useEffect, useState } from 'react'
import axios from 'axios';
import  MessageWithPhoto  from './message';

const messageBootExamp = {
    isUser : false,
    name : "Boot",
    message : "Hola! \n Como puedo ayudarte?"
}

export const ChatBootComponent = () => {
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);
    const [message, setMessage] = useState("")

    const [disabledMessage, setDisabledMessage] = useState(false)
    const [messages, setMessages] = useState([
        messageBootExamp,
        ])

    const toggleChatbot = async () => {
        const token = localStorage.getItem("token")

        console.log("Tokens : ", token);

        if (!isChatbotOpen && !token){
            const resp = await axios.post(`${process.env.REACT_APP_API_KEY }/chat/session/`)

            if (resp.status === 201){
                const token = resp.data.tokenBootAccess
                localStorage.setItem("token", token);
                setIsChatbotOpen(!isChatbotOpen);
            }

            return;
        }
        else if (!isChatbotOpen && token){
            const resp = await axios.post(`${process.env.REACT_APP_API_KEY }/chat/messages/`, {
                token
            })

            const messages = resp.data.map(e=>{
                return {
                    ...e,
                    name: e.isUser ? "Usuario" : "Boot"
                }
            })

            setMessages(messages)

            setIsChatbotOpen(!isChatbotOpen);

            return;
        }
        
        setIsChatbotOpen(!isChatbotOpen);
    };

    const sendMessage = async ()=>{
        setMessage("")
        const token = localStorage.getItem("token")
        // if (!token) return;

        // const customer = parseJwt(token)

        // console.log("Customer: ", customer);
        
        const name = localStorage.getItem("name") || "Usuario"

        messages.push({
            isUser : true,
            name : name,
            message : message
        })
        setMessages(JSON.parse(JSON.stringify(messages)))

        console.log( "Message: ", message);
        const resp = await axios.post(process.env.REACT_APP_API_KEY + "/chat/conversation/", {
            token,
            message
        }).catch(e=>{
            alert("Error al enviar el mensaje")
            return e.response;
        })


        console.log("Respuesta: ", resp);
        console.log("Data: ", resp.data);

        if (resp.status === 201){
            const message = resp.data.response;

            console.log("Message: ", message);
            messages.push({
                isUser : false,
                name : "Boot",
                message : message
            })
            console.log("Messages: ", messages);
            setMessages(JSON.parse(JSON.stringify(messages)))
        }
        else{
            alert("Tu sesion ha expirado. Por favor vuelve a iniciar sesion")
            
            messages.push({
                isUser : false,
                name : "Boot",
                message : "Gracias por tu tiempo. Uno de nuestros asistentes te contactara para brindarte mas detalles."
            })
            setMessages(JSON.parse(JSON.stringify(messages)))

            setDisabledMessage(true)

        }

    }


    return (
        <div className={`chatbot-section ${isChatbotOpen ? 'open' : ''}`}>
            <div onClick={toggleChatbot} className="header">
                <button >Chat</button>
            </div>
            {isChatbotOpen && (
                <>

                    <div className="content">
                        {
                            messages.map(e => <MessageWithPhoto  name={e.name} message={e.message} isUser={e.isUser}></MessageWithPhoto>)
                        }
                    </div>
                    <form onSubmit={(e)=>{e.preventDefault(); sendMessage()}} className="message-input">
                        <textarea 
                            disabled={disabledMessage}
                        value={message} onChange={(e)=>setMessage(e.target.value)} className="input-field" placeholder="Type your message..."></textarea>
                        <button
                            disabled={disabledMessage}
                            type='submit'
                            className="send-button">
                            <img className='send-icon' src="https://icons.veryicon.com/png/o/miscellaneous/feather-v423/send-21.png" alt="" />
                        </button>
                    </form>

                </>
            )}
        </div>
    );
}
